import React, { FC, useEffect } from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { useNavigate } from 'react-router-dom';
import ErrorBoundaryView from './components/ErrorBoundaryView/ErrorBoundaryView';

Bugsnag.start({
  apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
});

const ErrorView: FC = function ErrorView() {
  const navigate = useNavigate();

  useEffect(() => {
    // location.reload();
    // navigate(Links.DAILY_SCHEDULE_LINK);
  }, [navigate]);

  return <ErrorBoundaryView />;
};

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

export { ErrorBoundary, ErrorView };
export default Bugsnag;
